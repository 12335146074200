import React from 'react'
import Hero1 from "../assets/img/hero/hero-1.jpg";
// import Hero2 from "../assets/img/hero/hero-2.jpg";
// import Hero3 from "../assets/img/hero/hero-3.jpg";
import About1 from "../assets/img/about/about-1.png";
import About2 from "../assets/img/about/about-2.png";
import Room1 from "../assets/img/room/room-b1.jpeg";
import Room2 from "../assets/img/room/room-b2.jpeg";
import Travel from "../assets/img/services/travel.png";
import Catering from "../assets/img/services/catering.png";
import BabySitting from "../assets/img/services/baby-sitting.png";
import Laundry from "../assets/img/services/laundaring.png";
import HireDriver from "../assets/img/services/driver.png";
import BarDrink from "../assets/img/services/bardrink.png";
import Slider from "react-slick";
import Star from "../assets/img/star.png";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      {/* Hero Section Begin */}
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8">
              <div className="hero-text text-center">
                <h1>The Spades</h1>
                <p>
                  THE SPADES offers accommodation in Zirakpur. Boasting a
                  24-hour front desk, this property also provides guests with a
                  restaurant. All guest rooms in the hotel are fitted with a
                  kettle. At THE SPADES all rooms have air conditioning and a
                  flat-screen TV. Chandīgarh is 10 km from the accommodation,
                  while Kasauli is 31 km from the property.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-slider owl-carousel">
          <div
            className="hs-item set-bg"
            data-setbg="img/hero/hero-1.jpg"
            style={{
              backgroundImage: `url(${Hero1})`,
            }}
          ></div>
          {/* <div
            className="hs-item set-bg"
            style={{
              backgroundImage: `url(${Hero2})`,
              
            }}
          ></div> */}
          {/* <div
            className="hs-item set-bg"
            data-setbg="img/hero/hero-3.jpg"
            style={{
              backgroundImage: `url(${Hero3})`,
            }}
          ></div> */}
        </div>
      </section>
      {/* Hero Section End  */}

      {/* About Us Section Begin */}
      <section className="aboutus-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-text">
                <div className="section-title">
                  <span>About Us</span>
                </div>
                <p className="f-para">
                  HOTEL THE SPADES is a great choice for travellers looking for
                  a 3 star hotel in Zirakpur. It is located in Chandigarh
                  Rd.This Hotel stands out as one of the highly recommended
                  hotel in Zirakpur and is recommended by 100% of our guests.
                  Hotel is rated 5.0 out of 5, which is considered as excellent.
                  The property enjoys a great location advantage and provides
                  easy and fast connectivity to the major transit points of the
                  city. Some of the popular transit points from HOTEL THE SPADES
                  are ISBT Panchkula (6.1 kms), ISBT Chandigarh (12.1 kms), ISBT
                  Mohali (13.5 kms) and Chandigarh Airport (8.3 kms). The Hotel
                  is in proximity to some popular tourist attractions and other
                  places of interest in Zirakpur. Some of the tourist
                  attractions near HOTEL THE SPADES Elante Mall (5.5 kms), Mansa
                  Devi Temple (10.2 kms) and I.S. Bindra PCA Stadium (11.8 kms).
                </p>

                <p className="f-para">
                  From all the 3 Star hotels in Zirakpur, HOTEL THE SPADES is
                  very much popular among the tourists. A smooth
                  check-in/check-out process, flexible policies and friendly
                  management garner great customer satisfaction for this
                  property. The Hotel has standard Check-In time as 01:00 PM and
                  Check-Out time as 11:00 AM. It is a couple-friendly property,
                  hence it is absolutely safe for unmarried couples to stay
                  here.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-pic">
                <div className="row">
                  <div className="col-sm-6">
                    <img src={About1} alt="about-1" />
                  </div>
                  <div className="col-sm-6 mg-tp">
                    <img src={About2} alt="about-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Section End */}

      {/* Services Section End */}
      <section className="services-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>What We Do</span>
                <h2>Discover Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="service-item">
                <img src={Travel} alt="Travel" />
                <h4>Travel Plan</h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-item">
                <img src={Catering} alt="Catering" />
                <h4>Catering Service</h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-item">
                <img src={BabySitting} alt="BabySitting" />
                <h4>Accommodation</h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-item">
                <img src={Laundry} alt="Laundry" />
                <h4>Laundry</h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-item">
                <img src={HireDriver} alt="HireDriver" />
                <h4>Hire Driver</h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-item">
                <img src={BarDrink} alt="BarDrink" />
                <h4>Bar & Drink</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services Section End */}

      {/* Home Room Section Begin */}
      <section className="hp-room-section">
        <div className="container-fluid">
          <div className="hp-room-items">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div
                  className="hp-room-item set-bg"
                  data-setbg="img/room/room-b1.jpeg"
                  style={{
                    backgroundImage: `url(${Room1})`,
                  }}
                >
                  <div className="hr-text">
                    <h3>Deluxe Room</h3>
                    <h2>
                      ₹<span>2008</span>
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td className="r-o">Size:</td>
                          <td>220 sq.ft.</td>
                        </tr>
                        <tr>
                          <td className="r-o">Capacity:</td>
                          <td>Max persion 3</td>
                        </tr>
                        <tr>
                          <td className="r-o">Bed:</td>
                          <td>King Bed sized bed</td>
                        </tr>
                        <tr>
                          <td className="r-o">Services:</td>
                          <td>Wifi, TV, Housekeeping,...</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div
                  className="hp-room-item set-bg"
                  data-setbg="img/room/room-b2.jpeg"
                  style={{
                    backgroundImage: `url(${Room2})`,
                  }}
                >
                  <div className="hr-text">
                    <h3>Deluxe Room with Free Breakfast</h3>
                    <h2>
                      ₹<span>2730</span>
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          <td className="r-o">Size:</td>
                          <td>220 sq.ft.</td>
                        </tr>
                        <tr>
                          <td className="r-o">Capacity:</td>
                          <td>Max persion 3</td>
                        </tr>
                        <tr>
                          <td className="r-o">Bed:</td>
                          <td>King Bed sized bed</td>
                        </tr>
                        <tr>
                          <td className="r-o">Services:</td>
                          <td>Free Breakfast,Wifi, Television,...</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Room Section End */}

      {/* Testimonial Section Begin */}
      <section className="testimonial-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>Testimonials</span>
                <h2>What Customers Say?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <Slider {...settings}>
                <div className="testimonial-slider owl-carousel">
                  <div className="ts-item">
                    <p>
                      We enjoyed our brief stay her but wished we stayed another
                      night The breakfast was amazing All kinds of fruit eggs,
                      yogurt, pastries, cereal the kids had a blast Very clean
                      hotel great location Would stay again
                    </p>
                    <div className="ti-author d-flex justify-content-center">
                      <div className="rating d-flex align-items-center">
                        <img
                          src={Star}
                          alt="Star"
                          width="15"
                          height="15"
                          className="mr-1"
                        />{" "}
                        <img
                          src={Star}
                          alt="Star"
                          width="15"
                          height="15"
                          className="mr-1"
                        />{" "}
                        <img
                          src={Star}
                          alt="Star"
                          width="15"
                          height="15"
                          className="mr-1"
                        />{" "}
                        <img
                          src={Star}
                          alt="Star"
                          width="15"
                          height="15"
                          className="mr-1"
                        />{" "}
                        <img
                          src={Star}
                          alt="Star"
                          width="15"
                          height="15"
                          className="mr-1"
                        />
                      </div>
                      <h5> - priyanka jaswal</h5>
                    </div>
                  </div>
                </div>
                <div className="ts-item">
                  <p>
                    Had a very comfortable stay here with my family. The rooms
                    are spacious, very well maintained and neat and clean. The
                    staff was very resourceful and courteous. The food too was
                    good. Looking forward to another stay here in the coming
                    months. :)
                  </p>
                  <div className="ti-author d-flex ">
                    <div className="rating d-flex justify-content-center">
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                    </div>
                    <h5> - Arpita Dutta</h5>
                  </div>
                </div>
                <div className="ts-item">
                  <p>
                    I visited the place with my family and the experience was
                    amazing. The staff were courteous, rooms were clean and
                    hygienic. This is going to be my stay in the future whenever
                    I visit. Awesome experience ☺️.
                  </p>
                  <div className="ti-author d-flex ">
                    <div className="rating d-flex justify-content-center">
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                      <img
                        src={Star}
                        alt="Star"
                        width="15"
                        height="15"
                        className="mr-1"
                      />
                    </div>
                    <h5> - Prashant Jha</h5>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial Section End */}
    </div>
  );
}

export default Home;