import React from 'react'

const Contact = () => {
  return (
    <div>
        {/* Contact Section Begin */}
        <section class="contact-section spad">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="contact-text">
                  <h2>Contact Info</h2>
                  <table>
                    <tbody>
                      <tr>
                        <td class="c-o">Address:</td>
                        <td>Ambala Chandigarh Expy, near INDIAN OIL PETROL PUMP, adjoining HOTEL SAPPHIRE, Zirakpur, Punjab
                          140603</td>
                      </tr>
                      <tr>
                        <td class="c-o">Phone:</td>
                        <td>074472 00020</td>
                      </tr>
                      <tr>
                        <td class="c-o">Email:</td>
                        <td>hotelthespades@gmail.com</td>
                      </tr>
                      <tr>
                        <td class="c-o">Website:</td>
                        <td>www.thespades.in</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div class="col-lg-7 offset-lg-1">
                <form action="#" class="contact-form">
                  <div class="row">
                    <div class="col-lg-6">
                      <input type="text" placeholder="Your Name"/>
                    </div>
                    <div class="col-lg-6">
                      <input type="text" placeholder="Your Email"/>
                    </div>
                    <div class="col-lg-12">
                      <textarea placeholder="Your Message"></textarea>
                      <button type="submit">Submit Now</button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div class="map">
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.0606825994123!2d-72.8735845851828!3d40.760690042573295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85b24c9274c91%3A0xf310d41b791bcb71!2sWilliam%20Floyd%20Pkwy%2C%20Mastic%20Beach%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1578582744646!5m2!1sen!2sbd"
                height="470" style="border:0;" allowfullscreen=""></iframe> */}

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13727.631680857592!2d76.8171911!3d30.6647213!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x326b6b7c326df93c!2sHOTEL%20THE%20SPADES!5e0!3m2!1sen!2sin!4v1661430344385!5m2!1sen!2sin"
                width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade" title="The-Spades"></iframe>
            </div>
          </div>
        </section>
        {/* Contact Section End */}
    </div>
  )
}

export default Contact;