import React from 'react'
import FooterLogo from "../assets/img/footer-logo.png";
import Phone from "../assets/img/phone-call.png";
import Envelope from "../assets/img/envelope.png";
import Pin from "../assets/img/pin.png";



const Footer = () => {
  return (
    <div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-text">
            <div className="row">
              <div className="col-lg-6">
                <div className="ft-about">
                  <div className="logo">
                    <a href="/">
                      <img src={FooterLogo} alt="footer-logo" width="60" />
                    </a>
                  </div>
                  <p>
                    We inspire and reach millions of travelers
                    
                  </p>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-1">
                <div className="ft-contact text-left">
                  <h6>Contact Us</h6>
                  <ul>
                    <li>
                      <img
                        src={Phone}
                        alt="Phone"
                        className="mr-2"
                        width="25"
                      />
                      01762-518988, 01762-518987
                    </li>
                    <li>
                      <img
                        src={Envelope}
                        alt="Envelope"
                        className="mr-2"
                        width="25"
                      />
                      hotelthespades@gmail.com
                    </li>
                    <li>
                      <img src={Pin} alt="Pin" className="mr-2" width="25" />
                      Ambala Chandigarh Expy, near INDIAN OIL PETROL PUMP,
                      adjoining HOTEL SAPPHIRE, Zirakpur, Punjab 140603
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;