import React from 'react'
import AboutP1 from "../assets/img/about/about-p1.png";
import AboutP2 from "../assets/img/about/about-p2.png";
import AboutP3 from "../assets/img/about/about-p3.png";
import Tick from "../assets/img/about/tick.png";

const AboutUs = () => {
  return (
    <div>
      {/* Breadcrumb Section Begin */}
      <div class="breadcrumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-text">
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb Section End */}

      {/* About Us Page Section Begin */}
      <section class="aboutus-page-section spad">
        <div class="container">
          <div class="about-page-text">
            <div class="row">
              <div class="col-lg-6">
                <div class="ap-title">
                  <h2>Welcome To The Spades</h2>
                  <p>
                    HOTEL THE SPADES is a great choice for travellers looking
                    for a 3 star hotel in Zirakpur. It is located in Chandigarh
                    Rd.This Hotel stands out as one of the highly recommended
                    hotel in Zirakpur and is recommended by 100% of our guests.
                    Hotel is rated 5.0 out of 5, which is considered as
                    excellent. The property enjoys a great location advantage
                    and provides easy and fast connectivity to the major transit
                    points of the city
                  </p>
                </div>
              </div>
              <div class="col-lg-5 offset-lg-1">
                <ul class="ap-services">
                  <li>
                    <img src={Tick} alt="Tick" width="15" className="mr-2" />{" "}
                    Breakfast Included
                  </li>
                  <li>
                    <img src={Tick} alt="Tick" width="15" className="mr-2" />{" "}
                    Free Wifi.
                  </li>
                  <li>
                    <img src={Tick} alt="Tick" width="15" className="mr-2" />{" "}
                    Free Cancellation - Book @ ₹ 1
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="about-page-services">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="ap-service-item set-bg"
                  data-setbg="img/about/about-p1.png"
                  style={{
                    backgroundImage: `url(${AboutP1})`,
                  }}
                >
                  <div class="api-text">
                    <h3>Restaurants Services</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="ap-service-item set-bg"
                  data-setbg="img/about/about-p2.png"
                  style={{
                    backgroundImage: `url(${AboutP2})`,
                  }}
                >
                  <div class="api-text">
                    <h3>Rooms Services</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="ap-service-item set-bg"
                  data-setbg="img/about/about-p3.png"
                  style={{
                    backgroundImage: `url(${AboutP3})`,
                  }}
                >
                  <div class="api-text">
                    <h3>Event & Party</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Page Section End */}
    </div>
  );
}

export default AboutUs;