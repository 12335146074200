import React from 'react'
import Logo from "../assets/img/logo.png";
import "../assets/styles/style.css";
import {Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <header className="header-section">
        <div className="top-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="tn-left">
                  <li>
                    <i className="fa fa-phone"></i>+91-7447200020
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i> hotelthespades@gmail.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-item">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-2 ">
                <div className="logo">
                  <a href="/" >
                    <img src={Logo} alt="logo" />
                  </a>
                </div>
              </div>
              <div className="col-md-10">
                <div className="nav-menu">
                  <nav className="mainmenu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="rooms">Rooms</Link>
                      </li>
                      <li>
                        <Link to="about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;