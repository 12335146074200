import Footer from "./Components/Footer";
import Header from "./Components/Header";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Rooms from "./Pages/Rooms";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Header />
          {children}
          <Footer />
        </>
      )}
    />
  );
}

function App() {
  return (
      <Router>
        <Switch>
          <PublicRoute path="/" exact>
            <Home />
          </PublicRoute>
          <PublicRoute path="/rooms">
            <Rooms />
          </PublicRoute>
          <PublicRoute path="/about-us">
            <AboutUs />
          </PublicRoute>
          <PublicRoute path="/contact">
            <Contact />
          </PublicRoute>
        </Switch>
      </Router>
    //   <Footer />
    // </div>
  );
}

export default App;
