import React from 'react'
import Room1 from "../assets/img/room/room-b1.jpeg";
import Room2 from "../assets/img/room/room-b2.jpeg";


const Rooms = () => {
  return (
    <div>
      {/* Breadcrumb Section Begin */}
      <div class="breadcrumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-text">
                <h2>Our Rooms</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb Section End */}
      <section class="rooms-section spad">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
              <div class="room-item">
                <img src={Room1} alt="Room1" />
                <div class="ri-text">
                  <h4>Deluxe Room</h4>
                  <h3>
                    ₹<span>2008</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td class="r-o">Size:</td>
                        <td>220 sq.ft.</td>
                      </tr>
                      <tr>
                        <td class="r-o">Capacity:</td>
                        <td>Max persion 3</td>
                      </tr>
                      <tr>
                        <td class="r-o">Bed:</td>
                        <td>King Beds</td>
                      </tr>
                      <tr>
                        <td class="r-o">Services:</td>
                        <td>Wifi, Television, Geyser/Water Heater,...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="room-item">
                <img src={Room2} alt="Room2" />
                <div class="ri-text">
                  <h4>Deluxe Room with Free Breakfast</h4>
                  <h3>
                    ₹<span>2730</span>
                  </h3>
                  <table>
                    <tbody>
                      <tr>
                        <td class="r-o">Size:</td>
                        <td>220 sq.ft.</td>
                      </tr>
                      <tr>
                        <td class="r-o">Capacity:</td>
                        <td>Max persion 3</td>
                      </tr>
                      <tr>
                        <td class="r-o">Bed:</td>
                        <td>King Bed sized bed</td>
                      </tr>
                      <tr>
                        <td class="r-o">Services:</td>
                        <td>In-room Dining, Safety, Work Desk,...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Rooms